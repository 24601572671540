import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useForm, FormContainer, PasswordElement, TextFieldElement } from 'react-hook-form-mui'

import { Link } from 'components/link'
import { errorMessage } from 'data/errors'
import { useLogin, LoginArgs } from 'data/use-login'

export const Login = () => {
  const { loading, error, login } = useLogin()
  const formContext = useForm<LoginArgs>({})
  const { handleSubmit } = formContext

  return (
    <Container component="div" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>

        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(login)}
        >
          <TextFieldElement
            id="email"
            name="email"
            label="Email Address"
            required
            autoFocus
            fullWidth
            margin="normal"
            autoComplete="email"
          />
          <PasswordElement
            id="password"
            name="password"
            label="Password"
            required
            fullWidth
            margin="normal"
            autoComplete="current-password"
            sx={{ mb: 3 }}
          />
          {error && (
            <Grid container justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <Alert severity="error">
                  { errorMessage(error) }
                </Alert>
              </Grid>
            </Grid>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading
              ? <CircularProgress />
              : 'Sign In'
            }
          </Button>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" to="/forgot-password">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link variant="body2" to="/sign-up">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
    </Container>
  )
}
