/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation ForgotPassword($orgId: ID!, $email: String!) {\n    forgotPassword(orgId: $orgId, email: $email)\n  }\n": types.ForgotPasswordDocument,
    "\n  mutation Login($orgId: ID!, $email: String!, $password: String!) {\n    login(orgId: $orgId, email: $email, password: $password) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation ResetPassword($newPassword: String!) {\n    resetPassword(newPassword: $newPassword) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.ResetPasswordDocument,
    "\n   query Self {\n     self {\n       id\n       org {\n         id\n         name\n       }\n       user {\n         id\n         firstName\n         lastName\n       }\n     }\n   }\n": types.SelfDocument,
    "\n  mutation SignUp($args: SignUpInput!) {\n    signUp(args: $args) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.SignUpDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ForgotPassword($orgId: ID!, $email: String!) {\n    forgotPassword(orgId: $orgId, email: $email)\n  }\n"): (typeof documents)["\n  mutation ForgotPassword($orgId: ID!, $email: String!) {\n    forgotPassword(orgId: $orgId, email: $email)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($orgId: ID!, $email: String!, $password: String!) {\n    login(orgId: $orgId, email: $email, password: $password) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($orgId: ID!, $email: String!, $password: String!) {\n    login(orgId: $orgId, email: $email, password: $password) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResetPassword($newPassword: String!) {\n    resetPassword(newPassword: $newPassword) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPassword($newPassword: String!) {\n    resetPassword(newPassword: $newPassword) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query Self {\n     self {\n       id\n       org {\n         id\n         name\n       }\n       user {\n         id\n         firstName\n         lastName\n       }\n     }\n   }\n"): (typeof documents)["\n   query Self {\n     self {\n       id\n       org {\n         id\n         name\n       }\n       user {\n         id\n         firstName\n         lastName\n       }\n     }\n   }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignUp($args: SignUpInput!) {\n    signUp(args: $args) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SignUp($args: SignUpInput!) {\n    signUp(args: $args) {\n      authToken\n      self {\n        id\n        org {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;