import { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { SelfContext } from 'contexts/self'
import { AccountMenu } from 'components/account-menu'

export function Layout() {
  const navigate = useNavigate()
  const selfContext = useContext(SelfContext)
  const { self, loading } = (selfContext || {})

  const renderUserElement = () => {
    if (loading) {
      return <CircularProgress color="secondary" size="24" />
    }

    return self
      ? <AccountMenu />
      : (
        <Button
          color="inherit"
          onClick={() => navigate('/login')}
        >
          Login
        </Button>
      )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Games
          </Typography>
          { renderUserElement() }
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  )
}
