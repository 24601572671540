import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useForm, FormContainer, PasswordElement } from 'react-hook-form-mui'

import { getPossibleResetToken } from 'data/auth'

import { Link } from 'components/link'
import { errorMessage } from 'data/errors'
import { useResetPassword, ResetPasswordArgs } from 'data/use-reset-password'

export const ResetPassword = () => {
  const { loading, error, resetPassword } = useResetPassword()
  const formContext = useForm<ResetPasswordArgs>({})
  const { handleSubmit } = formContext

  const resetToken = getPossibleResetToken()

  const renderMissingTokenAlert = () => (
    <Grid container justifyContent="center" sx={{ mt: 2 }}>
      <Grid item sx={{ mb: 2 }}>
        <Alert severity="warning">
          The password reset token is missing or invalid.
          Please use the link below to send a new password reset email.
        </Alert>
      </Grid>
    </Grid>
  )

  const renderResetForm = () => (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(resetPassword)}
    >
      <PasswordElement
        id="newPassword"
        name="newPassword"
        label="New Password"
        required
        fullWidth
        margin="normal"
        autoComplete="new-password"
        sx={{ mb: 3 }}
      />
      {error && (
        <Grid container justifyContent="center">
          <Grid item sx={{ mb: 2 }}>
            <Alert severity="error">
              { errorMessage(error) }
            </Alert>
          </Grid>
        </Grid>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={loading}
        sx={{ mb: 2 }}
      >
        {loading
          ? <CircularProgress />
          : 'Save Password and Login'
        }
      </Button>
    </FormContainer>
  )

  return (
    <Container component="div" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>

        {resetToken
          ? renderResetForm()
          : renderMissingTokenAlert()
        }

        <Grid container justifyContent="center">
          <Grid item>
            <Link variant="body2" to="/forgot-password">
              Send a new password reset email
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
