import { Routes, Route } from 'react-router-dom'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { SelfProvider } from 'contexts/self'

import { Layout } from 'components/layout'

import { Games } from 'pages/games'
import { Login } from 'pages/login'
import { Logout } from 'pages/logout'
import { SignUp } from 'pages/sign-up'
import { ForgotPassword } from 'pages/forgot-password'
import { ResetPassword } from 'pages/reset-password'
import { Privacy } from 'pages/policies/privacy'
import { Terms } from 'pages/policies/terms'

function App() {
  return (
    <div>
      <SelfProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Games />}/>
            <Route path="login" element={<Login />}/>
            <Route path="logout" element={<Logout />}/>
            <Route path="sign-up" element={<SignUp />}/>
            <Route path="forgot-password" element={<ForgotPassword />}/>
            <Route path="reset-password" element={<ResetPassword />}/>

            <Route path="privacy" element={<Privacy />}/>
            <Route path="terms" element={<Terms />}/>
          </Route>
        </Routes>
      </SelfProvider>
    </div>
  )
}

export default App
