import { createContext, useEffect, useMemo, ReactNode } from 'react'
import { Self } from 'data/gql/types'
import { useSelf } from 'data/use-self'
import { getCurrentAuthToken, setCurrentAuthToken } from 'data/auth'

export type SelfContextType = {
  self: Self | null | undefined
  loading: boolean
  refreshSelf: () => void
}

export const SelfContext = createContext<SelfContextType | null>(null)

type Props = {
  children?: ReactNode
}
export const SelfProvider = ({ children }: Props) => {
  const { self, loading, error, refetch } = useSelf()

  useEffect(() => {
    // If we have a stored token, and fetching Self failed,
    // clear the token.
    if (error && getCurrentAuthToken()) {
      setCurrentAuthToken()
    }
  }, [error])

  const contextValue = useMemo(() => {
    return {
      loading,
      refreshSelf: refetch,
      self: error ? null : self,
    }
  }, [self, loading, refetch, error])

  return (
    <SelfContext.Provider value={contextValue}>
      {children}
    </SelfContext.Provider>
  )
}
