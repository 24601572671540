interface PersistentStorage {
  getItem(key: string): string | null
  setItem(key: string, value?: unknown): void
}

class LocalStorage implements PersistentStorage {
  getItem(key: string) {
    const item = localStorage.getItem(key)

    if (item === null) return undefined

    if (item === 'null') return null
    if (item === 'undefined') return undefined

    try {
      return JSON.parse(item)
    } catch {
      console.warn(`Encountered non-json storage value: ${item}`)
    }

    return item
  }

  setItem(key: string, value?: unknown) {
    if (value === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }
}

class MockStorage implements PersistentStorage {
  getItem() {
    return null
  }

  setItem(key: string, value?: unknown) {
    console.info(`Setting '${key}' - '${JSON.stringify(value)}' in MockStorage`)
  }
}

export const persistentStorage = window?.localStorage
  ? new LocalStorage()
  : new MockStorage()
