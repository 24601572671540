import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useForm, FormContainer, TextFieldElement } from 'react-hook-form-mui'

import { useForgotPassword, ForgotPasswordArgs } from 'data/use-forgot-password'

import { errorMessage } from 'data/errors'
const customErrorMap = {
  'NOT_FOUND': 'A user account for the given email could not be found.'
}

export const ForgotPassword = () => {
  const { triggerForgotPassword, success, loading, error } = useForgotPassword()
  const formContext = useForm<ForgotPasswordArgs>({})
  const { handleSubmit } = formContext

  const triggerText = success
    ? 'Re-Send Email'
    :'Send Password Reset'

  return (
    <Container component="div" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Send Password Reset Email
        </Typography>

        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(triggerForgotPassword)}
        >
          <TextFieldElement
            id="email"
            name="email"
            label="Email Address"
            required
            autoFocus
            fullWidth
            margin="normal"
            autoComplete="email"
          />
          {error && (
            <Grid container justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <Alert severity="error">
                  { errorMessage(error, customErrorMap) }
                </Alert>
              </Grid>
            </Grid>
          )}
          {success && (
            <Grid container justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <Alert severity="info">
                  You should receive an email shortly allowing you to reset your password.
                </Alert>
              </Grid>
            </Grid>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading
              ? <CircularProgress />
              : triggerText
            }
          </Button>
        </FormContainer>
      </Box>
    </Container>
  )
}
