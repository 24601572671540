import { useMutation } from '@apollo/client'

import { gql } from 'data/gql'
import { useCurrentOrg } from './use-current-org'

const FORGOT_PASSWORD = gql(/* GraphQL */ `
  mutation ForgotPassword($orgId: ID!, $email: String!) {
    forgotPassword(orgId: $orgId, email: $email)
  }
`)

export type ForgotPasswordArgs = {
  email: string
}

export const useForgotPassword = () => {
  const { org, loading: orgLoading } = useCurrentOrg()
  const [triggerForgotPassword, mutationEffects] = useMutation(FORGOT_PASSWORD)

  return {
    ...mutationEffects,

    loading: orgLoading || mutationEffects.loading,

    success: !!mutationEffects?.data?.forgotPassword,

    triggerForgotPassword: (args: ForgotPasswordArgs) => org && triggerForgotPassword({
      variables: { orgId: org?.id, ...args }
    })
  }
}
