import { useMutation } from '@apollo/client'

import { gql } from 'data/gql'
import { usePostAuth } from './auth'

const RESET_PASSWORD = gql(/* GraphQL */ `
  mutation ResetPassword($newPassword: String!) {
    resetPassword(newPassword: $newPassword) {
      authToken
      self {
        id
        org {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`)

export type ResetPasswordArgs = {
  newPassword: string
}

export const useResetPassword = () => {
  const [resetPassword, { data: authResult, ...mutationEffects }] = useMutation(RESET_PASSWORD)

  usePostAuth(authResult?.resetPassword)

  return {
    ...mutationEffects,

    resetPassword: (args: ResetPasswordArgs) => resetPassword({ variables: args })
  }
}
