import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { current } from 'utils/env'
import { AuthenticationResult } from 'data/gql/types'
import { persistentStorage } from 'utils/persistent-storage'
import { SelfContext } from 'contexts/self'

const tokenKey = `auth-token-${current}`

export function getCurrentAuthToken() {
  return persistentStorage.getItem(tokenKey)
}

export function setCurrentAuthToken(token?: string) {
  persistentStorage.setItem(tokenKey, token)
}

export function getPossibleResetToken() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  return urlParams.get('token')
}

export function usePostAuth(authResult?: AuthenticationResult | null) {
  const selfContext = useContext(SelfContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (authResult?.authToken) {
      setCurrentAuthToken(authResult.authToken)

      if (typeof selfContext?.refreshSelf === 'function') {
        selfContext.refreshSelf()
      }

      navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authResult?.authToken])
}
