import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useForm, FormContainer, PasswordElement, TextFieldElement } from 'react-hook-form-mui'

import { Link } from 'components/link'
import { errorMessage } from 'data/errors'
import { useSignUp, SignUpArgs } from 'data/use-sign-up'

export const SignUp = () => {
  const { loading, error, signUp } = useSignUp()
  const formContext = useForm<SignUpArgs>({})
  const { handleSubmit } = formContext

  return (
    <Container component="div" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Create a new account
        </Typography>

        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(signUp)}
        >
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextFieldElement
                id="firstname"
                name="firstName"
                label="First Name"
                required
                fullWidth
                autoFocus
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldElement
                id="lastName"
                name="lastName"
                label="Last Name"
                required
                fullWidth
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldElement
                id="email"
                name="email"
                label="Email Address"
                required
                fullWidth
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordElement
                id="password"
                name="password"
                label="Password"
                required
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress />
            ) : 'Sign Up'}
          </Button>
          <Grid container justifyContent="center">
            {error && (
              <Grid item sx={{ mb: 2 }}>
                <Alert severity="error">
                  { errorMessage(error) }
                </Alert>
              </Grid>
            )}
            <Grid item>
              <Link href="#" variant="body2" to="/login">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
    </Container>
  )
}
