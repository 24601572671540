import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

import { client } from 'data'
import { setCurrentAuthToken } from 'data/auth'

export const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // Clear any currently stored auth token
    setCurrentAuthToken(undefined)
    client.resetStore()

    navigate('/')
  })

  return (
    <CircularProgress />
  )
}
