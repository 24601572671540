import { Org } from 'data/gql/types'

type Results = {
  loading: boolean
  org: Org | undefined
}

// TODO: Make this actually hit the backend based on the current
// domain to figure out the current Org.
export const useCurrentOrg = (): Results => {
  return {
    loading: false,
    org: {
      __typename: 'Org',
      id: '1',
      name: 'Mel Sports',
    }
  }
}
