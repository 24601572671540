import Typography, { TypographyProps } from '@mui/material/Typography'

export const Title = ({ children }: TypographyProps) => (
  <Typography
    component="h1"
    variant="h4"
    sx={{
      alignSelf: 'center',
      marginBottom: 1,
    }}
  >
    {children}
  </Typography>
)

export const SectionHeader = ({ children }: TypographyProps) => (
  <Typography
    component="h2"
    variant="h5"
    sx={{
      marginTop: 2,
    }}>
    {children}
  </Typography>
)

export const SectionSubHeader = ({ children }: TypographyProps) => (
  <Typography
    component="h3"
    variant="h6"
    sx={{
      marginTop: 1,
    }}>
    {children}
  </Typography>
)

export const SectionParagraph = ({ children }: TypographyProps) => (
  <Typography
    variant="body1"
    sx={{
      marginTop: 1,
    }}>
    {children}
  </Typography>
)
