import { useQuery } from '@apollo/client'
import { gql } from 'data/gql'

const SELF = gql(/* GraphQL */ `
   query Self {
     self {
       id
       org {
         id
         name
       }
       user {
         id
         firstName
         lastName
       }
     }
   }
`)

export const useSelf = () => {
  const { data, ...effects } = useQuery(SELF)

  return {
    ...effects,
    self: data?.self
  }
}
