import { useMutation } from '@apollo/client'

import { gql } from 'data/gql'
import { useCurrentOrg } from './use-current-org'
import { usePostAuth } from './auth'

const SIGN_UP = gql(/* GraphQL */ `
  mutation SignUp($args: SignUpInput!) {
    signUp(args: $args) {
      authToken
      self {
        id
        org {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`)

export type SignUpArgs = {
  email: string
  password: string
  firstName: string
  lastName: string
}

export const useSignUp = () => {
  const { org, loading: orgLoading } = useCurrentOrg()
  const [signUp, { data: authResult, ...signUpEffects }] = useMutation(SIGN_UP)

  usePostAuth(authResult?.signUp)

  return {
    ...signUpEffects,

    loading: orgLoading || signUpEffects.loading,

    signUp: (args: SignUpArgs) => org && signUp({
      variables: { args: { orgId: org?.id, ...args } }
    })
  }
}
