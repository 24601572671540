import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { getCurrentAuthToken, getPossibleResetToken } from './auth'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_URL
})

const authLink = setContext((_, { headers }) => {
  // If a reset token is present in the URL, assume that we're
  // performing a password reset and use that, otherwise fallback
  // to using any stored auth token.
  const token = getPossibleResetToken() || getCurrentAuthToken()

  return {
    headers: {
      ...headers,

      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
