import { ApolloError } from '@apollo/client'

const defaultError = 'Oops! Something went wrong. Please close and reopen the app to try again.'

const errorCodeMap: Record<string, string> = {
  'EMAIL_ALREADY_IN_USE': 'An account with this email address already exists. Try signing in.',
  'INVALID_PASSWORD': 'Email address or password are incorrect.',
}

const errorCodes = Object.keys(errorCodeMap)

function getErrorCode(error?: ApolloError): string {
  return error?.graphQLErrors?.[0]?.extensions?.code as string || ''
}

export function isKnownError(error?: ApolloError): boolean {
  return errorCodes.includes(getErrorCode(error))
}

export function errorMessage(error?: ApolloError, customMap?: Record<string, string>): string {
  const code = getErrorCode(error)

  return customMap?.[code] || errorCodeMap[code] || defaultError
}
