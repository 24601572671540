import { useMutation } from '@apollo/client'

import { gql } from 'data/gql'
import { useCurrentOrg } from './use-current-org'
import { usePostAuth } from './auth'

const LOGIN = gql(/* GraphQL */ `
  mutation Login($orgId: ID!, $email: String!, $password: String!) {
    login(orgId: $orgId, email: $email, password: $password) {
      authToken
      self {
        id
        org {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`)

export type LoginArgs = {
  email: string
  password: string
}

export const useLogin = () => {
  const { org, loading: orgLoading } = useCurrentOrg()
  const [login, { data: authResult, ...loginEffects }] = useMutation(LOGIN)

  usePostAuth(authResult?.login)

  return {
    ...loginEffects,

    loading: orgLoading || loginEffects.loading,

    login: (args: LoginArgs) => org && login({
      variables: { orgId: org?.id, ...args }
    })
  }
}
